@font-face {
  font-family: 'Avenir';
  src: url('./assets/fonts/AvenirBook/AvenirBook.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('./assets/fonts/AvenirBook/AvenirBlack.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Dharma';
  src: url('./assets/fonts/Dharma/DharmaGothicE_Bold_R.otf');
  font-weight: bold;
}

@font-face {
  font-family: 'Dharma';
  src: url('./assets/fonts/Dharma/DharmaGothicE_Light_I.otf');
  font-weight: 100;
}

@font-face {
  font-family: 'Dharma';
  src: url('./assets/fonts/Dharma/DharmaGothicE_Light_R.otf');
  font-weight: 100;
}

@font-face {
  font-family: 'Dharma';
  src: url('./assets/fonts/Dharma/DharmaGothicE_Regular_R.otf');
  font-weight: normal;
}

@font-face {
  font-family: 'MonumentExtended';
  src: url('./assets/fonts/MonumentExtended/MonumentExtended-Regular.otf');
  font-weight: normal;
}

@font-face {
  font-family: 'MonumentExtended';
  src: url('./assets/fonts/MonumentExtended/MonumentExtended-Ultrabold.otf');
  font-weight: bold;
}

@import './assets/scss/variables.scss';
@import './assets/scss/main.scss';
@import './assets/scss/header.scss';
@import './assets/scss/home.scss';
@import './assets/scss/about.scss';
@import './assets/scss/contact.scss';
@import './assets/scss/careers.scss';
@import './assets/scss/footer.scss';
