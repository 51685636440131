.footer {
    &.footer-container{
        padding-top: 35px;
        padding-bottom: 35px;
    }
    background-color: $black;
    .logo-container{
        a{
            color: grey;
            font-size: 14px;
        }
        display: flex;
        flex-direction: column;
    }
    .footer-menu-links{
        padding-top: 20px;
        padding-bottom: 20px;
        a{
            color: $white;
            font-family: $familyD;
            font-size: 22px;
            font-weight: $regular;
            text-transform: uppercase;
            &:not(:last-child){
                margin-bottom: 15px
            }
        }
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (min-width: $breakpoint) {
    .footer {
        &.footer-container{
            padding-top: 45px;
            padding-bottom: 45px;
            display: flex;
            align-items: center;
        }
        .logo-container{
            width: 20%;
            padding-right: 10px;
        }
        .footer-menu-links{
            padding-top: 0;
            padding-bottom: 0;
            text-transform: uppercase;
            a{
                font-size: 24px;
                margin-right: 50px;
                &:not(:last-child){
                    margin-bottom: 0;
                }
            }
            flex-direction: row;
        }
    }
}