*{
    font-family: $familyA;
    font-size: 18px;
    color: $black;
}

a {
    text-decoration: none;
}

.p-lg{
    font-size: 29px;
    font-weight: bold;
    line-height: 35px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.d-flex{
    display: flex;
}

h1{
    font-size: 40px;
    font-family: $familyD;
    font-weight: normal;
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 40px;
    text-transform: uppercase;
}

.desc-title{
    font-family: $familyM;
    text-transform: uppercase;
    font-size: 25px;
    font-weight: bold;
    margin: 0;
}

.grey {color: $grey}

.section-image{
    width: 100%;
    height: 71vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}

.section-gradaint{
    width: 100%;
    height: 26px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("./../img/gradiant-image.png");
}

.black-bg{
    height:60px;
    width:100%;
    background-color:black;
}

.page-gradiant{
    position: relative;
    bottom: 30px;
    padding-left: 30px;
}

.section-desc-container{
    padding: 0 $spacing-mob 40px;
}

.desktop-only{
    display: none;
}

@media only screen and (min-width: $breakpoint) {
    *{
        font-size: 20px;
    }

    .p-lg{
        font-size: 31px;
    }

    .desktop-only{
        display: block;
    }
    .mob-only{
        display: none;
    }
    .black-bg{
        height:100px;
    }
    .page-gradiant{
        position: relative;
        bottom: 50px;
        padding-left: 70px;
    }
    .section-desc-container{
        padding: 0 $spacing-desk 60px;
    }

    h1{
        font-size: 80px;
        line-height: 80px;
    }

    .desc-title{
        font-size: 35px;
    }
    .width-100 {
        background-size: 100% !important;
    }
}