$familyM: 'MonumentExtended', sans-serif;
$familyD: 'Dharma', sans-serif;
$familyA: 'Avenir', sans-serif;
$breakpoint: 1024px;


$black: #000;
$white: #FFFFFF	;
$grey: #747474;

$medium: 500;
$regular: 300;
$bold: 700;

$spacing-mob: 30px;
$spacing-desk: 70px;